import React from "react";
import styles from "./content.module.css";
import doctor from "../../assets/doctor.png";
import Textfield from "../../components/Textfield";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import banner from "../../assets/booknow_home.png";

const BlogContent = () => {
  return (
    <div className={styles.blogContent}>
      <div className={styles.doctorCard}>
        <div className={styles.docProfile}>
          <img src={doctor} alt="" />
          <Textfield
            type="h2"
            style={{
              color: "#222222 !important",
              padding: 0,
            }}
          >
            Anna Rue
          </Textfield>
          <Textfield
            type="h5"
            style={{ color: "#222222", fontWeight: 300, textAlign: "center" }}
          >
            We understand just how closely connected oral health is to your
            overall health.
          </Textfield>
          <div className={styles.button}>
            <Textfield type="h4">Open Profile</Textfield>
          </div>
        </div>
        <div className={styles.share}>
          <Textfield type="h5">Share:</Textfield>
          <div className={styles.socialConntainer}>
            <div className={styles.icon}>
              <FaFacebook />
            </div>
            <div className={styles.icon}>
              <BsTwitterX />
            </div>
            <div className={styles.icon}>
              <FaLinkedin />
            </div>
            <div className={styles.icon}>
              <MdEmail />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blog}>
        <Textfield
          type="h2"
          style={{
            color: "#222222 !important",
            lineHeight: "28.89px",
          }}
        >
          Stay Consistent with Your Oral Hygiene Habits
        </Textfield>
        <Textfield
          type="span"
          style={{ color: "#222222", fontWeight: 300, lineHeight: "28.89px" }}
        >
          The European languages are members of the same family. Their separate
          existence is a myth. For science, music, sport, etc, Europe uses the
          same vocabulary. The languages only differ in their grammar, their
          pronunciation and their most common words. Everyone realizes why a new
          common language would be desirable: one could refuse to pay expensive
          translators. To achieve this, it would be necessary to have uniform
          grammar, pronunciation and more common words. If several languages
          coalesce, the grammar of the resulting language is more simple and
          regular than that of the individual languages. The new common language
          will be more simple and regular than the existing European languages.
          It will be as simple as Occidental; in fact, it will be Occidental. To
          an English person, it will seem like simplified English, as a
          skeptical Cambridge friend of mine told me what Occidental is. The
          European languages are members of the same family. Their separate
          existence is a myth. The languages only differ in their grammar, their
          pronunciation and their most common words. Everyone realizes why a new
          common language would be desirable: one could refuse to pay expensive
          translators. The European languages are members of the same family.
        </Textfield>
        <div className={styles.greenCard}>
          <Textfield
            type="h2"
            colorType="white"
            style={{ lineHeight: "28.89px" }}
          >
            Remember, Good Oral Hygiene Involves:
          </Textfield>
          <Textfield
            type="span"
            colorType="white"
            style={{ lineHeight: "28.89px" }}
          >
            The languages only differ in their grammar, their pronunciation and
            their most common words. Everyone realizes why a new common language
            would be desirable: one could refuse to pay expensive translators.
          </Textfield>
          <Textfield
            type="span"
            colorType="white"
            style={{ lineHeight: "28.89px", marginLeft: 50 }}
          >
            To achieve this, it would be necessary to have uniform grammar,
            pronunciation and more common words.
          </Textfield>
          <Textfield
            type="span"
            colorType="white"
            style={{ lineHeight: "28.89px", marginLeft: 50 }}
          >
            If several languages coalesce, the grammar of the resulting language
            is more simple and regular than that of the individual languages.
          </Textfield>
          <Textfield
            type="span"
            colorType="white"
            style={{ lineHeight: "28.89px" }}
          >
            The new common language will be more simple and regular than the
            existing European languages. It will be as simple as Occidental.
          </Textfield>
        </div>
        <Textfield
          type="span"
          style={{
            color: "#222222",
            fontWeight: 300,
            lineHeight: "28.89px",
          }}
        >
          To achieve this, it would be necessary to have uniform grammar,
          pronunciation and more common words. If several languages coalesce,
          the grammar of the resulting language is more simple and regular than
          that of the individual languages.
        </Textfield>
        <Textfield
          type="h2"
          style={{
            color: "#222222 !important",
            lineHeight: "28.89px",
            paddingTop: 40,
          }}
        >
          Keep High Sugar Snacks and Treats to the Minimum
        </Textfield>
        <Textfield
          type="span"
          style={{
            color: "#222222",
            fontWeight: 300,
            lineHeight: "28.89px",
          }}
        >
          To achieve this, it would be necessary to have uniform grammar,
          pronunciation and more common words. If several languages coalesce,
          the grammar of the resulting language is more simple and regular than
          that of the individual languages.
        </Textfield>

        <Textfield
          type="h2"
          style={{
            color: "#222222 !important",
            lineHeight: "28.89px",
            paddingTop: 40,
          }}
        >
          Avoid Beverages that Stain Your Teeth
        </Textfield>
        <Textfield
          type="span"
          style={{
            color: "#222222",
            fontWeight: 300,
            lineHeight: "28.89px",
          }}
        >
          The new common language will be more simple and regular than the
          existing European languages. It will be as simple as Occidental; in
          fact, it will be Occidental. To an English person, it will seem like
          simplified English, as a skeptical Cambridge friend of mine told me
          what Occidental is. The European languages are members of the same
          family. Their separate existence is a myth.
        </Textfield>

        <Textfield
          type="h2"
          style={{
            color: "#222222 !important",
            lineHeight: "28.89px",
            paddingTop: 40,
          }}
        >
          Don’t Use Your Teeth like Tools
        </Textfield>
        <Textfield
          type="span"
          style={{
            color: "#222222",
            fontWeight: 300,
            lineHeight: "28.89px",
          }}
        >
          The new common language will be more simple and regular than the
          existing European languages. It will be as simple as Occidental; in
          fact, it will be Occidental. To an English person, it will seem like
          simplified English, as a skeptical Cambridge friend of mine told me
          what Occidental is. The European languages are members of the same
          family. Their separate existence is a myth.
        </Textfield>

        <img className={styles.blogImg} src={banner} alt="" />

        <Textfield
          type="h2"
          style={{
            color: "#222222 !important",
            lineHeight: "28.89px",
            paddingTop: 40,
          }}
        >
          Eliminate Bad Habits
        </Textfield>
        <Textfield
          type="span"
          style={{
            color: "#222222",
            fontWeight: 300,
            lineHeight: "28.89px",
          }}
        >
          The new common language will be more simple and regular than the
          existing European languages. It will be as simple as Occidental; in
          fact, it will be Occidental. To an English person, it will seem like
          simplified English, as a skeptical Cambridge friend of mine told me
          what Occidental is. The European languages are members of the same
          family. Their separate existence is a myth. To achieve this, it would
          be necessary to have uniform grammar, pronunciation and more common
          words. If several languages coalesce, the grammar of the resulting
          language is more simple and regular than that of the individual
          languages.
        </Textfield>

        <Textfield
          type="h2"
          style={{
            color: "#222222 !important",
            lineHeight: "28.89px",
            paddingTop: 40,
          }}
        >
          Brighten Your Smile with Professional Teeth Whitening
        </Textfield>
        <Textfield
          type="span"
          style={{
            color: "#222222",
            fontWeight: 300,
            lineHeight: "28.89px",
          }}
        >
          The new common language will be more simple and regular than the
          existing European languages. It will be as simple as Occidental; in
          fact, it will be Occidental. To an English person, it will seem like
          simplified English, as a skeptical Cambridge friend of mine told me
          what Occidental is. The European languages are members of the same
          family. Their separate existence is a myth.
        </Textfield>
      </div>
    </div>
  );
};

export default BlogContent;
