import React, { useState } from "react";
import styles from "./coursetabview.module.css";
import Textfield from "../../components/Textfield";
import { BiArrowFromTop, BiArrowToBottom } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const data = [
  {
    chapterName: "Intra-oral Scanning Module (10 Modules) ",
    topics: [
      {
        topicName: "Setting up of IOS Scanner   ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Scanner Handling Techniques ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Machine Maintenance & Troubleshooting ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "What is a good IOS Scan? ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Retraction & Isolation Techniques  ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Ideal IOS Process step by step  ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Digital Workflow Integration ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Communication  ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Accuracy and Quality Control   ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Potential Failures and how to counter it?   ",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
    ],
  },
  {
    chapterName: "Advance dental clinic ODS courses (14 Module)",
    topics: [
      {
        topicName: "Title",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Title2",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Title3",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
    ],
  },
  {
    chapterName: "Advance dental clinic ODS courses (14 Module)",
    topics: [
      {
        topicName: "Title",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Title2",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Title3",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
    ],
  },
  {
    chapterName: "Advance dental clinic ODS courses (14 Module)",
    topics: [
      {
        topicName: "Title",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Title2",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
      {
        topicName: "Title3",
        topicDescript:
          "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience",
      },
    ],
  },
];

const CourseModule = () => {
  return (
    <>
     <h1 className={styles.moduleHeader}>What will you learn?</h1>
  
    <div className={styles.chapterContainer}>
      {data.map((chapter, chapterIndex) => (
        <div key={chapterIndex} className={styles.chapter}>
          <Textfield type="h4" style={{ marginLeft: 30, fontWeight: 400 }}>
            {chapter.chapterName}
          </Textfield>
          <div className={styles.circle}></div>
          <div className={styles.verticalLine}></div>
          <div className={styles.topicContainer}>
            {chapter.topics.map((topic, topicIndex) => (
              <AccordionItem key={topicIndex} topic={topic} />
            ))}
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default CourseModule;

const AccordionItem = ({ topic }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    
      

    <div className={styles.accordionItem}>
      <div className={styles.accordionHeader} onClick={toggleAccordion}>
        <Textfield type="h4" style={{ fontWeight: 600 }}>
          {topic.topicName}
        </Textfield>
        <span>{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
      </div>
      {isOpen && (
        <div className={styles.accordionContent}>
          <Textfield type="span" style={{ fontWeight: 400, fontSize: 13 }}>
            {topic.topicDescript}
          </Textfield>
        </div>
      )}
    </div>
   
  );
};
