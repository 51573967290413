import styles from './cardWrapper.module.css';

const CardWrapper = ({ children, additionalStyles = {} }) => {


    return (
        <div className={styles.cardWrapper} style={additionalStyles}>
            {children}
        </div>
    )

}

export default CardWrapper;