import { Link } from "react-router-dom";
import inclinedArrow from "../../assets/inclinedArrow.svg";
import Textfield from "../Textfield";
import styles from "./blogsCardStyles.module.css";

const BlogCard = ({ imgSrc, headline, readMoreLink }) => {
  return (
    <Link to="/blog">
      <div className={styles.blogCard}>
        <img src={imgSrc} alt="blogimg" className={styles.blogImg} />
        <Textfield
          type="h3"
          style={{
            marginBlock: "20px",
            fontWeight: "bolder",
            textAlign: "left",
          }}
        >
          {headline}
        </Textfield>
        <hr />
        <div className={styles.readMoreDiv}>
          <Textfield
            type="h5"
            style={{
              marginBlock: "20px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            Read More
          </Textfield>
          <img src={inclinedArrow} alt="arrowimg" />
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
