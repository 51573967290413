import ContactInfo from '../../molecules/Home/ContactInfo';
import Textfield from '../Textfield';
import ContactForm from './ContactForm';
import styles from './contactStyles.module.css';
import FeaturesBox from './FeaturesBox';
import useWindowDimensions from '../../hooks/useWindowsDimention';

import Teeth3d from '../../assets/teeth3d.svg';
import invisibleBraces from '../../assets/invisibleBraces.svg'


const ContactComp = () => {

    const { width } = useWindowDimensions();

    return (
        <main>
            <section className={styles.formSec}>
                <ContactForm />
            </section>
            <ContactInfo bgColor='#c9e2ff' />
            {/* <section className={styles.certificateSec}>
                <Textfield type="h1" style={{ marginTop: '10px', marginBottom: '20px', textAlign: 'center', ...(width < 600 && { fontSize: '24px' }) }} >
                    About this Professional Certificate Courses
                </Textfield>
                <div className={styles.certificateFeatures}>
                    <div className={styles.featuresBoxContainer}>
                        <FeaturesBox imgSrc={Teeth3d} heading='Next-Gen' description='Crafted with top-notch 3D printing, laser tech and industry leading PU material.' />
                        <FeaturesBox imgSrc={Teeth3d} heading='Next-Gen' description='Crafted with top-notch 3D printing, laser tech and industry leading PU material.' />
                        <FeaturesBox imgSrc={Teeth3d} heading='Next-Gen' description='Crafted with top-notch 3D printing, laser tech and industry leading PU material.' />
                    </div>
                    <img src={invisibleBraces} alt='invisibleBraces' className={styles.contImg} />
                </div>
            </section> */}
        </main>
    )
}

export default ContactComp;