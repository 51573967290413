import React from "react";
import styles from "./works.module.css";
import Textfield from "../../components/Textfield";
import Button from "../../components/Buttons";
import work1 from "../../assets/course/work1.png";
import { HashLink as HLink } from "react-router-hash-link";
const CourseHowItWorks = () => {
  return (
    <div className={styles.work}>
      <Textfield colorType="blue" type="h1">
        The Package Advantage
      </Textfield>
      <Textfield
        colorType="blue"
        type="h5"
        style={{ marginTop: 10, fontWeight: 400 }}
      >
        Our Clinical Suites Modules are designed as per different learning .
        <br />
        preferences & objectives within the dental sector
      </Textfield>
      <div className={styles.steps}>
        <div className={styles.step}>
          <img src={work1} alt="" />
          <Textfield colorType="blue" type="h1">
            Base Package
          </Textfield>
          <Textfield
            colorType="blue"
            type="h5"
            style={{ marginTop: 10, fontWeight: 400 }}
          >
            The Base Module offers a comprehensive online learning experience
            with high-quality video content and engaging gamification, making it
            perfect for those looking to strengthen their clinical knowledge
          </Textfield>
        </div>
        <div className={styles.step}>
          <img src={work1} alt="" />
          <Textfield colorType="blue" type="h1">
            Hybrid Package
          </Textfield>
          <Textfield
            colorType="blue"
            type="h5"
            style={{ marginTop: 10, fontWeight: 400 }}
          >
            Our Hybrid Module enhances the Base Module with Extended Reality
            (XR) for PC-based immersive learning. Explore dental procedures and
            techniques in 3D without extra hardware.
          </Textfield>
        </div>
        <div className={styles.step}>
          <img src={work1} alt="" />
          <Textfield colorType="blue" type="h1">
            VR Package
          </Textfield>
          <Textfield
            colorType="blue"
            type="h5"
            style={{ marginTop: 10, fontWeight: 400 }}
          >
            Our VR Module immerses you in a virtual dental practice, bridging
            the gap between theory and practice. Experience hands-on learning in
            a risk-free environment, preparing you for real-world clinical
            success.
          </Textfield>
        </div>
      </div>
      <HLink smooth to="#contactus">  <Button>book a free demo class</Button></HLink>
    </div>
  );
};

export default CourseHowItWorks;
