import Textfield from '../../components/Textfield';
import styles from './tabs.module.css';

const Tabs = ({ tabsArr, currentTabIdx, setCurrentTabIdx, additionalStyles }) => {
    
    return (
        <div className={styles.tabContainer} style={additionalStyles}>
            {tabsArr?.map((el, i) => <TabComp content={el} idx={i + 1} currentTabIdx={currentTabIdx} setCurrentTabIdx={setCurrentTabIdx} />)}
        </div>
    )
}

export default Tabs;

const TabComp = ({ content, idx, currentTabIdx, setCurrentTabIdx }) => {

    return (
        <div className={styles.tab} style={{ backgroundColor: idx === currentTabIdx ? 'white' : 'transparent' }} onClick={() => setCurrentTabIdx(idx)}>
            <Textfield type="h5" style={{ textAlign: 'center', color: idx === currentTabIdx ? '#002744' : 'white' }} >
                {content}
            </Textfield>
        </div>
    )

}