import React from "react";
import Layout from "../../molecules/Layout";
import CoursesOverviewComp from "../../components/CoursesOverview";


const CoursesOverview = () => {
    return (
        <Layout>
            <CoursesOverviewComp />
        </Layout>
    );
};

export default CoursesOverview;
