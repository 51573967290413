import React from "react";
import Layout from "../../molecules/Layout";
import AboutComp from "../../components/About";


const About = () => {
  return (
    <Layout>
      <AboutComp />
    </Layout>
  );
};

export default About;
