import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from './carousel.module.css';


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    }
};

const CommonCarousel = ({ children, handleAfterChange }) => {


    return (

        <Carousel className={styles.carousel} showDots={true} infinite={true} responsive={responsive} removeArrowOnDeviceType={["tablet", "mobile", "desktop"]} centerMode={true} afterChange={handleAfterChange}>
            {children}
        </Carousel>

    )

}

export default CommonCarousel;