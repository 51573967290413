import React from "react";
import Layout from "../../molecules/Layout";
import styles from "./Courses.module.css";
import Textfield from "../../components/Textfield";
import course from "../../assets/start_course.svg";
import course_hero from "../../assets/course_hero_image.png";
import CourseTabView from "../../molecules/CourseTabView/CourseTabView";
import CourseCertificates from "../../molecules/CourseCertificates/CourseCertificates";
import CoursePricing from "../../molecules/CoursePricingSection/CoursePricing";
import CourseHowItWorks from "../../molecules/CourseHowItWorks/CourseHowItWorks";
import blogImg from "../../assets/blogImg.svg";
import BlogCard from "../../components/blogsCard";
import CourseSmileSteps from "../../molecules/CourseSmileSteps/CourseSmileSteps";
import CourseSpecialized from "../../molecules/CourseSpecialized/CourseSpecialized";
import { HashLink as Link } from 'react-router-hash-link';
const Courses = () => {
  const blogs = [
    {
      imgSrc: blogImg,
      headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
      readMoreLink: "",
    },
    {
      imgSrc: blogImg,
      headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
      readMoreLink: "",
    },
    {
      imgSrc: blogImg,
      headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
      readMoreLink: "",
    },
    {
      imgSrc: blogImg,
      headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
      readMoreLink: "",
    },
  ];

  return (
    <Layout>
      <div className={styles.courses}>
        <div className={styles.headText}>
          <Textfield colorType="white" type="h1">
            Intra-Oral Scanning Course
          </Textfield>
          <Textfield colorType="white" type="span" style={{ marginTop: 20 }}>
            Become an expert in intra-oral scanning with our comprehensive
            training on IOS technology, digital workflows, and troubleshooting,
            ensuring you provide the best possible care and treatment outcomes
            for your patients.
          </Textfield>
          <img
            src={course}
            alt=""
            height={12}
            style={{ marginTop: 5, marginBottom: 20 }}
          />
          <div className={styles.enroll}>
            {" "}
            <Link smooth to="#contactus"> 
            <Textfield colorType="white" type="span">
             Enquire Now 
            </Textfield>
            </Link>
          </div>
        </div>
        <div className={styles.headImg}>
          <img src={course_hero} alt="" />
        </div>
      </div>
      <CourseTabView />
      <CourseCertificates />
      <CoursePricing />
      <CourseHowItWorks />
      <CourseSpecialized />
      <CourseSmileSteps />
      <section className={styles.blogsSec}>
        {blogs?.map((el) => (
          <BlogCard
            imgSrc={el.imgSrc}
            headline={el.headline}
            readMoreLink={el.readMoreLink}
          />
        ))}
      </section>
    </Layout>
  );
};

export default Courses;
