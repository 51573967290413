import Textfield from "../../Textfield"
import styles from '../contactStyles.module.css'


const FeaturesBox = ({ heading, description, imgSrc }) => {
    return (
        <div className={styles.featuresBox}>
            <img src={imgSrc} alt="imgSrc" />
            <div>
                <Textfield type="h3" style={{ fontWeight: 'bolder' }}>
                    {heading}
                </Textfield>
                <Textfield type="h5">
                    {description}
                </Textfield>
            </div>
        </div>
    );
}

export default FeaturesBox;
