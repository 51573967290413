import React, { useState } from "react";
import styles from "./coursetabview.module.css";
import { FiChevronDown, FiChevronsLeft, FiChevronUp } from "react-icons/fi";
import { BiLeftArrow } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import Textfield from "../../components/Textfield";

const dummyData = [
  {
    title: "When will I have access to the lectures and assignments?",
    description:
      "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience to increase your knowledge and abilities with COBOL as a whole. You’ll work with a variety of IBM, COBOL, and mainframe assets, project scenarios, including COBOL programming basics, TSO, ISPF, gaining practical experience with mainframe data management and software development techniques.",
  },
  {
    title: "What will I get if I subscribe to this Specialization?",
    description:
      "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience to increase your knowledge and abilities with COBOL as a whole. You’ll work with a variety of IBM, COBOL, and mainframe assets, project scenarios, including COBOL programming basics, TSO, ISPF, gaining practical experience with mainframe data management and software development techniques.",
  },
  {
    title: "Is financial aid available?",
    description:
      "COBOL is scalable, mature, and self-documenting. You'll learn about COBOL from its birth and through its evolution. You'll learn new features and aspects of the language, while also getting hands-on experience to increase your knowledge and abilities with COBOL as a whole. You’ll work with a variety of IBM, COBOL, and mainframe assets, project scenarios, including COBOL programming basics, TSO, ISPF, gaining practical experience with mainframe data management and software development techniques.",
  },
];

const CourseQA = () => {
  return (
    <div className={styles.courseQA}>
      {dummyData.map((item, index) => (
        <AccordionItem key={index} item={item} />
      ))}
    </div>
  );
};

export default CourseQA;

const AccordionItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.accordionItem1}>
      <div className={styles.accordionHeader1} onClick={toggleAccordion}>
        <div className={styles.icon}>
          <IoIosArrowForward />
        </div>
        <Textfield type="h4" style={{ fontWeight: 600 }}>
          {item.title}
        </Textfield>
      </div>
      {isOpen && (
        <div className={styles.accordionContent1}>
          <Textfield type="span" style={{ fontWeight: 400, fontSize: 13 }}>
            {item.description}
          </Textfield>
        </div>
      )}
    </div>
  );
};
