import React from "react";
import Layout from "../../molecules/Layout";
import BlogsComp from "../../components/Blogs";

const Blogs = () => {
  return (
    <Layout>
      <BlogsComp />
    </Layout>
  );
};

export default Blogs;
