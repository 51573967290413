import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import Courses from "./pages/Courses";
import Contact from "./pages/Contact";
import CoursesOverview from "./pages/CoursesOverview";
import Blog from "./pages/Blog";
import ScrollToTop from "./molecules/ScrollToTop";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<CoursesOverview />} />
          <Route path="/courses-detail" element={<Courses />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
