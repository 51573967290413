import Textfield from "../../Textfield"
import styles from '../about.module.css'


const ServicesBox = ({ imgSrc, heading, content }) => {

    return (
        <div className={styles.serviceBox}>
            <img src={imgSrc} alt="serviceImg" />
            <div className={styles.contentBox}>
                <Textfield type="h3" style={{ color: '#013A65', marginBottom: '10px' }}>
                    {heading}
                </Textfield>
                <Textfield type="h4" style={{ color: '#013A65B5' }}>
                    {content}
                </Textfield>
            </div>
        </div>
    )

}

export default ServicesBox;