import React from "react";
import styles from "./smile.module.css";
import course1 from "../../assets/course.png";
import Textfield from "../../components/Textfield";

const data = [
  {
    id: 1,
    title: "Specialized Training & Education Programs ",
    desc: "Adopt latest dental tech with specialized online and VR training in the digital dental industry.  ",
  },
  {
    id: 2,
    title: "Focus on Continuing Education ",
    desc: "Stay ahead with our accessible training options, latest technologies and practices for licensure renewal.",
  },
  {
    id: 3,
    title: "Shift Towards Online & Remote Learning ",
    desc: "The last few years have accelerated the shift to online training, with learners valuing the importance of digital education. ",
  },
  {
    id: 4,
    title: "Integration of VR & Simulation  ",
    desc: "Advanced VR simulations revolutionize dental training, providing interactive, risk-free environments for superior skill development. ",
  },
];

const CourseSmileSteps = () => {
  return (
    <div className={styles.smileContainer}>
      <div className={styles.whiteContainer}>
        <div className={styles.infoContainer}>
          <Textfield
            type="h1"
            style={{
              marginBlock: "20px",
              fontWeight: "bolder",
              textAlign: "left",
            }}
          >
         Understanding the Market Needs & Trends 
          
          </Textfield>
          <div className={styles.itemContainer}>
            {data.map((item) => (
              <ListContainer id={item.id} title={item.title} desc={item.desc} />
            ))}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={course1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CourseSmileSteps;

const ListContainer = ({ id, title, desc }) => {
  return (
    <div className={styles.item}>
      <div className={styles.numbercirlce}>
        <div className={styles.number}>{id}</div>
      </div>
      <div className={styles.info}>
        <Textfield
          type="h4"
          style={{
            textAlign: "left",
          }}
        >
          {title}
        </Textfield>
        <Textfield
          type="span"
          style={{
            textAlign: "left",
            fontWeight: 300,
            fontSize: 13,
          }}
        >
          {desc}
        </Textfield>
      </div>
    </div>
  );
};
