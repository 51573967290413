import React from "react";
import styles from "./Contactinfo.module.css";
import Textfield from "../../../components/Textfield";
import { IoLocationOutline, IoPhonePortraitSharp } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";

const ContactInfo = ({ bgColor }) => {
  return (
    <div  className={styles.contactInfo} style={{ backgroundColor: bgColor }}>
      <div className={styles.contactDetail}>
        <Textfield
          colorType="blue"
          type="h6"
          style={{ fontWeight: 400, color: "rgba(94, 178, 242, 1)" }}
        >
          Our Contact Details
        </Textfield>
        <Textfield colorType="blue" type="h1">
          Contact Us Now
        </Textfield>
        <Textfield
          colorType="blue"
          type="h5"
          style={{ marginTop: 10, fontWeight: 400 }}
        >
          Our expert team is here to attend to your queries.
        </Textfield>

        <div className={styles.contactList}>
          <div className={styles.contactItem}>
            <div className={styles.icon}>
              <IoPhonePortraitSharp color="#013A65" />
            </div>
            <Textfield colorType="blue" type="h6" style={{ fontWeight: 400 }}>
              +919384477477
            </Textfield>
          </div>
          <div className={styles.contactItem}>
            <div className={styles.icon}>
              <MdOutlineMailOutline color="#013A65" />
            </div>
            <Textfield colorType="blue" type="h6" style={{ fontWeight: 400 }}>
              +dentamedi@example.com
            </Textfield>
          </div>
          <div className={styles.contactItem}>
            <div className={styles.icon}>
              <IoLocationOutline color="#013A65" />
            </div>
            <Textfield colorType="blue" type="h6" style={{ fontWeight: 400 }}>
              +3 Wall St, New York 10005
            </Textfield>
          </div>
        </div>
      </div>
      <div className={styles.contactMap}>
        <div className={styles.blue}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.6368171322324!2d-122.08789912405172!3d37.42205833239948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fba02425dad8f%3A0x6c296c66619367e0!2sGoogleplex!5e0!3m2!1sen!2sin!4v1721150486329!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ borderRadius: 10, border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
