import React, { useState } from "react";
import styles from "./coursetabview.module.css";
import { FaRegNewspaper } from "react-icons/fa";
import CourseOverview from "./CourseOverview";
import CourseModule from "./CourseModule";
import CourseQA from "./CourseQA";

const CourseTabView = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { icon: <FaRegNewspaper size={14} />, name: "Overveiw " },
    { icon: <FaRegNewspaper size={14} />, name: "Course Modules " },
    { icon: <FaRegNewspaper size={14} />, name: "Q&A " },
  ];
  const content = [<CourseOverview />, <CourseModule />, <CourseQA />];

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabHeaders}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tabButton} ${
              activeTab === index ? styles.active : ""
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.icon}
            {tab.name}
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>{content[activeTab]}</div>
    </div>
  );
};

export default CourseTabView;
