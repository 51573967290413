import Textfield from '../Textfield';
import styles from './blogStyles.module.css'

import blogImg from '../../assets/blogImg.svg';
import BlogCard from '../blogsCard';
import useWindowDimensions from '../../hooks/useWindowsDimention';

const BlogsComp = () => {

    const { width } = useWindowDimensions();

    const blogs = [{ imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }, { imgSrc: blogImg, headline: 'Oral Cancer Awareness: Signs,Symptoms, and Prevention', readMoreLink: '' }]

    return (
        <main>
            <section className={styles.headerSec}>
                <Textfield type="h1" style={{ 'marginBlock': '10px', 'marginInline': '20px', fontSize: width > 600 ? '48px' : '24px', textDecoration: 'underline #00000080 solid 2px', textUnderlineOffset: '15px', lineHeight: '1.5' }}>
                    Welcome To Our Blog Section
                </Textfield>
            </section>
            <section className={styles.blogsSec}>
                {blogs?.map((el) => <BlogCard imgSrc={el.imgSrc} headline={el.headline} readMoreLink={el.readMoreLink} />)}
            </section>
        </main>
    )
}

export default BlogsComp;