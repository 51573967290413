import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import Button from "../Buttons";
import { Link } from "react-router-dom";
import Textfield from "../Textfield";
import logo from "../../assets/logolight.png";
import logodark from "../../assets/logodark.png";
import useWindowDimensions from "../../hooks/useWindowsDimention";
import { RiMenu3Line } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import course1 from "../../assets/course.png";
import { HashLink as HLink } from "react-router-hash-link";
const Navbar = ({ theme = "light" }) => {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const hoverRef = useRef(null);

  const toggleSlide = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hoverRef.current && !hoverRef.current.contains(event.target)) {
        setIsHovered(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className={`navbar ${theme}`}>
      <div className="navbar-brand" style={{ width: "20vw" }}>
        <img
          src={logodark}
          alt="logo"
          style={theme !== "light" ? { filter: "brightness(0) invert(1)" } : {}}
        />
        {/* <span className="navbar-brand-text">Logo</span> */}
      </div>
      {width > 960 ? (
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/">
              <Textfield
                colorType={theme == "light" ? "black" : "white"}
                type="h5"
              >
                Home
              </Textfield>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about">
              <Textfield
                colorType={theme == "light" ? "black" : "white"}
                type="h5"
              >
                About Us
              </Textfield>
            </Link>
          </li>
          <li
            className="nav-item"
            style={{ position: "relative" }}
            onClick={() => setIsHovered(true)}
            onMouseEnter={() => setIsHovered(true)}
          >
            {/* <Link to="/courses"> */}
            <Textfield
              colorType={theme == "light" ? "black" : "white"}
              type="h5"
            >
              Courses
            </Textfield>
            {isHovered && (
              <div className="hover-content" ref={hoverRef}>
                {/* Your content here */}
                <CardHover
                  title={"dental clinic courses "}
                  desc={
                    "Our VR course has design for levelling your skills and make you expert "
                  }
                  src={course1}
                  link={"/courses"}
                />
                <CardHover
                  title={"dental lab courses "}
                  desc={
                    "Our VR course has design for levelling your skills and make you expert "
                  }
                  src={course1}
                  link={"/courses-detail"}
                />
              </div>
            )}
            {/* </Link> */}
          </li>

          <li className="nav-item">
            <Link to="/blogs">
              <Textfield
                colorType={theme == "light" ? "black" : "white"}
                type="h5"
              >
                Blogs
              </Textfield>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact">
              <Textfield
                colorType={theme == "light" ? "black" : "white"}
                type="h5"
              >
                Contact Us
              </Textfield>
            </Link>
          </li>
        </ul>
      ) : null}
      <div
        className="navbar-brand"
        style={
          width > 900
            ? { width: "17vw" }
            : {
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }
        }
      >
          <HLink smooth to="#contactus">  <Button type="allBlue">Book a free demo class</Button> </HLink>
        {width < 900 ? (
          <RiMenu3Line
            size={30}
            onClick={toggleSlide}
            style={{ marginLeft: 20 }}
          />
        ) : null}
      </div>
      {isOpen ? (
        <div className={`slide-container ${isOpen ? "open" : ""}`}>
          <div className="slide-content">
            <div className="cross">
              <RxCross1 size={30} onClick={toggleSlide} color="#000" />
            </div>
            <ul>
              <li className="nav-item-mobile">
                <Link to="/">
                  <Textfield colorType={"black"} type="h4">
                    Home
                  </Textfield>
                </Link>
              </li>
              <li className="nav-item-mobile">
                <Link to="/about">
                  <Textfield colorType={"black"} type="h4">
                    About Us
                  </Textfield>
                </Link>
              </li>
              <li className="nav-item-mobile">
                <Link to="/courses">
                  <Textfield colorType={"black"} type="h4">
                    Courses
                  </Textfield>
                </Link>
              </li>
              <li className="nav-item-mobile">
                <Link to="/blogs">
                  <Textfield colorType={"black"} type="h4">
                    Blogs
                  </Textfield>
                </Link>
              </li>
              <li className="nav-item-mobile">
                <Link to="/contact">
                  <Textfield colorType={"black"} type="h4">
                    Contact Us
                  </Textfield>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </nav>
  );
};

export default Navbar;

const CardHover = ({ title, desc, src, link }) => {
  return (
    <Link to={link}>
      <div className="navcard">
        <Textfield type="h1">{title}</Textfield>
        <Textfield
          colorType="blue"
          type="h5"
          style={{ marginTop: 10, fontWeight: 400 }}
        >
          {desc}
        </Textfield>
        <img src={src} alt="" />
      </div>
    </Link>
  );
};
