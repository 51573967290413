import React from "react";
import Layout from "../../molecules/Layout";
import styles from "./blog.module.css";
import blog9 from "../../assets/course/blog-9.png";
import Textfield from "../../components/Textfield";
import BlogContent from "../../molecules/BlogContent/BlogContent";
import BlogCard from "../../components/blogsCard";
import blogImg from "../../assets/blogImg.svg";

const blogs = [
  {
    imgSrc: blogImg,
    headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
    readMoreLink: "",
  },
  {
    imgSrc: blogImg,
    headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
    readMoreLink: "",
  },
  {
    imgSrc: blogImg,
    headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
    readMoreLink: "",
  },
  {
    imgSrc: blogImg,
    headline: "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
    readMoreLink: "",
  },
];

const Blog = () => {
  return (
    <Layout>
      <div className={styles.blog}>
        <div className={styles.blogHeader}>
          <img src={blog9} alt="" />
        </div>
        <div className={styles.info}>
          <Textfield
            type="h6"
            style={{
              fontWeight: "500",
              marginBlock: "10px",
              letterSpacing: "3px",
              color: "#222222 !important",
            }}
          >
            PRIORITISE YOUR DENTAL HEALTH
          </Textfield>
          <Textfield
            type="h1"
            style={{
              color: "#222222 !important",
            }}
          >
            Clear Aligner Braces vs Traditional Braces
          </Textfield>
          <Textfield
            type="h4"
            style={{ marginBlock: "20px", color: "#222222", fontWeight: 300 }}
          >
            Our experienced and compassionate office team strives to provide a
            positive, stress-free experience at every visit.
          </Textfield>
          <div className={styles.avtarContainer}>
            <img src={blog9} alt="" />
            <Textfield
              type="h5"
              style={{ marginBlock: "20px", color: "#222222", fontWeight: 300 }}
            >
              cmsmasters
            </Textfield>
            <Textfield
              type="h5"
              style={{ marginBlock: "20px", color: "#A5AEA8", fontWeight: 300 }}
            >
              January 2, 2024
            </Textfield>
          </div>
        </div>
      </div>

      <BlogContent />
      <section className={styles.blogsSec}>
        {blogs?.map((el) => (
          <BlogCard
            imgSrc={el.imgSrc}
            headline={el.headline}
            readMoreLink={el.readMoreLink}
          />
        ))}
      </section>
    </Layout>
  );
};

export default Blog;
