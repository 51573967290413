import React from "react";
import { IoPlayCircleOutline } from "react-icons/io5";
import Button from "../../components/Buttons";
import Textfield from "../../components/Textfield";
import ContactInfo from "../../molecules/Home/ContactInfo";
import Layout from "../../molecules/Layout";
import styles from "./Home.module.css";
import spec1 from "../../assets/spec1.svg";
import spec2 from "../../assets/spec2.svg";
import spec3 from "../../assets/spec3.png";
import spec4 from "../../assets/spec4.svg";
import smallImage from "../../assets/smallImage.png";
import course1 from "../../assets/home/Dental Clinic Courses.png";
import course2 from "../../assets/home/Dental Lab Courses.png";
import benefitsTeeth from '../../assets/benifits-teeth.svg'
import Slider from "../../molecules/Slider";
import useWindowDimensions from "../../hooks/useWindowsDimention";
import { CardSlider } from "../../molecules/CardSlider";
import { HashLink as HLink } from 'react-router-hash-link';
import Carousel3D from "../../molecules/Carousel3D";

const Home = () => {
  const { width } = useWindowDimensions();
  return (
    <Layout isHome>
      <div className={styles.profesion}>
        <Textfield colorType="blue" type="h1">
          Our History
        </Textfield>
        <Textfield
          colorType="blue"
          type="h5"
          style={{ marginTop: 10, fontWeight: 400 }}
        >
          DGA was founded by Dr. Manish Chhabra, a digital dentistry pioneer with 17+ years of experience. He identified a gap in accessible, immersive training for dentists and dental assistants to master new technologies. DGA's VR-powered platform empowers dentists with convenient, cost-effective training, reducing their reliance on assistants and freeing their time for patient care. DGA aims to revolutionize dental training through immersive, engaging experiences that fit users' schedules.
        </Textfield>
        <HLink smooth to="#contactus">  <Button>Enroll Now </Button> </HLink>
      </div>
      <Slider />
      <div className={styles.courses}>
        <ColorButton label="Our Courses" />
        <Textfield colorType="blue" type="h1" style={{ lineHeight: 1.1 }}>
          Master Digital Dentistry
          <br /> with Our Skills
        </Textfield>
        <div className={styles.courseContainer}>
          <div className={styles.blueCard}>
            <ColorButton label="Dental" type="blue" />
            <Textfield colorType="white" type="h2">
              Dental Clinic Courses
            </Textfield>
            <Textfield
              colorType="grey"
              type="h5"
              style={{ marginTop: 10, fontWeight: 400, marginRight: 150 }}
            >
              Learn all courses of digital dentistry regarding dental clinics
            </Textfield>
            <img src={course1} alt="" />
          </div>
          <div className={styles.blueCard}>
            <ColorButton label="Dental" type="blue" />
            <Textfield colorType="white" type="h2">
              Dental Lab Courses
            </Textfield>
            <Textfield
              colorType="grey"
              type="h5"
              style={{ marginTop: 10, fontWeight: 400, marginRight: 150 }}
            >
              Learn all courses of digital dentistry regarding dental labs
            </Textfield>
            <img src={course2} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.bluesmallBanner}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={styles.overlayStyle}>
            <img src={smallImage} alt="" />
            <div className={styles.blueOverlayStyle}></div>
          </div>
          <div className={styles.flexCol}>
            <Textfield colorType="white" type="h1">
              Get An Expert Professor Opinion
            </Textfield>
            {width > 900 ? (
              <Textfield colorType="grey" type="h5">
                Our courses are designed for levelling up your skills and making you an expert
              </Textfield>
            ) : null}
          </div>
        </div>

        <HLink smooth to="#contactus">  <Button type="white">Book a free demo class</Button> </HLink>
      </div>

      <div className={styles.booknow}>
        <div className={styles.benefitsBox}>
          <Textfield colorType="white" type="h2">
            Benefits You Will Get In Our Courses
          </Textfield>
          <Textfield colorType="white" type="h5">
            Our VR course has designed for levelling your skills and make you expert
          </Textfield>
          <div className={styles.benefits}>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
                DENTAL<br /> IMPLANTS
              </Textfield>
            </div>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
                DENTISTRY<br /> SERVICES
              </Textfield>
            </div>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
                DAILY<br /> PREVENTION
              </Textfield>
            </div>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
                Teeth<br /> Whitening
              </Textfield>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button style={{ border: '20px', padding: '10px', marginInline: 'auto !important', fontSize: '10px !important' }}>BOOK A FREE DEMO CLASS {'->'}</Button>
          </div>
        </div>
        <div className={styles.booknowItem} >
          <Carousel3D />
        </div>

      </div>

      <div className={styles.spec}>
        <ColorButton label="Our Speciality" />
        <Textfield colorType="blue" type="h1">
          Our Speciality
        </Textfield>
        <div className={styles.specList}>
          <div className={styles.specItem}>
            <img src={spec1} alt="" />
            <Textfield colorType="blue" type="h2">
              8+
            </Textfield>
            <Textfield colorType="grey">
              Courses{" "}
            </Textfield>
          </div>
          <div className={styles.specItem}>
            <img src={spec2} alt="" />
            <Textfield colorType="blue" type="h2">
              5+
            </Textfield>
            <Textfield colorType="grey">
              Educators on our panel {" "}
            </Textfield>
          </div>
          <div className={styles.specItem}>
            <img src={spec3} alt="" />
            <Textfield colorType="blue" type="h2">
              500+
            </Textfield>
            <Textfield colorType="grey">
              Learners{" "}
            </Textfield>
          </div>
          <div className={styles.specItem}>
            <img src={spec4} alt="" />
            <Textfield colorType="blue" type="h2">
              1000+
            </Textfield>
            <Textfield colorType="grey">
              Mins of VR learning{" "}
            </Textfield>
          </div>
        </div>
      </div>

      <div className={styles.videoSection}>
        <div className={styles.alwaysHere}>
          <IoPlayCircleOutline
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            color="#fff"
            size={70}
          />
          <Textfield
            colorType="white"
            type="h1"
            style={{ position: "absolute", bottom: "70px", left: "40px" }}
          >
            Always Here for Your
            <br />
            Dental Education
          </Textfield>
        </div>
      </div>
      <ContactInfo />
    </Layout>
  );
};

export default Home;

const ColorButton = ({ type = "white", label }) => {
  return (
    <div className={type === "white" ? styles.whiteButton : styles.blueButton}>
      {label}
    </div>
  );
};
