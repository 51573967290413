import React from "react";
import Layout from "../../molecules/Layout";
import ContactComp from "../../components/Contact";

const Contact = () => {
    return (
        <Layout isContactBanner={false}>
            <ContactComp />
        </Layout>
    );
};

export default Contact;
