import React from "react";
import "./Slider.css";
import Img1 from "../../assets/home/slider/h1-img-8.jpg.png";
import Img2 from "../../assets/home/slider/img2.png";
import Img3 from "../../assets/home/slider/img3.png";
import Img4 from "../../assets/home/slider/img4.png";
import Img5 from "../../assets/home/slider/img5.png";

const Slider = () => {
  return (
    <div className="slider">
      <div className="background"></div>
      <div className="circle1"></div>
   
      <div className="slide-track">
        {/* Original Slides */}
        <div className="slide"><img src={Img1} alt="Slide 1" /></div>
        <div className="slide"><img src={Img2} alt="Slide 2" /></div>
        <div className="slide"><img src={Img3} alt="Slide 3" /></div>
        <div className="slide"><img src={Img4} alt="Slide 4" /></div>
        <div className="slide"><img src={Img5} alt="Slide 5" /></div>
        
        {/* Duplicated Slides */}
        <div className="slide"><img src={Img1} alt="Slide 1" /></div>
        <div className="slide"><img src={Img2} alt="Slide 2" /></div>
        <div className="slide"><img src={Img3} alt="Slide 3" /></div>
        <div className="slide"><img src={Img4} alt="Slide 4" /></div>
        <div className="slide"><img src={Img5} alt="Slide 5" /></div>

        <div className="slide"><img src={Img1} alt="Slide 1" /></div>
        <div className="slide"><img src={Img2} alt="Slide 2" /></div>
        <div className="slide"><img src={Img3} alt="Slide 3" /></div>
        <div className="slide"><img src={Img4} alt="Slide 4" /></div>
        <div className="slide"><img src={Img5} alt="Slide 5" /></div>
      </div>
    </div>
  );
};

export default Slider;
