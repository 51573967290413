import Textfield from '../../Textfield';
import styles from './dentistCardStyles.module.css';

const DentistCard = ({ imgSrc, name, designation, description, additinalStyles = {} }) => {

    return (
        <div className={styles.dentistCard} style={{ ...additinalStyles }}>
            <img src={imgSrc} alt='dentistImg' />
            <div className={styles.dentistDetails}>
                <Textfield type="h2" colorType="white" style={{ 'marginTop': '10px' }}>
                    {name}
                </Textfield>
                <Textfield type="h5" style={{ 'marginTop': '10px', color: '#0075FF' }}>
                    {designation}
                </Textfield>
                <Textfield type="h5" colorType="white" style={{
                    'marginBlock': '20px', 'width': '80%',
                    'marginInline': 'auto'
                }}>
                    {description}
                </Textfield>
            </div>
        </div>
    )
}

export default DentistCard;