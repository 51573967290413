import BlogCard from "../blogsCard"
import Button from "../Buttons"
import Textfield from "../Textfield";
import styles from './fromTheBlogs.module.css'

const FromTheBlogsWrapper = ({ blogs }) => {

    return (
        <div className={styles.fromTheBlogsContainer}>
            <Textfield type="h2" style={{ 'marginBlock': '20px', 'fontWeight': 'bolder', 'textAlign': 'center' }}>
                Our Blog Section
            </Textfield>
            <div className={styles.fromTheBlogsWrapper}>
                {blogs?.map((el) => <BlogCard imgSrc={el.imgSrc} headline={el.headline} readMoreLink={el.readMoreLink} />)}
            </div>
            <Button type='filledLightBlue'>
                Read More New Posts
            </Button>
        </div>
    )
}

export default FromTheBlogsWrapper;